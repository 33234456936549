@import "~@csstools/normalize.css";
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";
@import "./mixins/breakpoints";
@import "./general/body";
@import "./vendor/index";

@layer utilities {
  .full-width-border::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
  }
}