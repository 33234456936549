body {
  @apply h-full bg-gray-100 font-sans leading-normal tracking-normal;
}

#root {
  @apply h-full flex flex-col;
}

h2 {
  scroll-margin-top: 65px;

  @include mq($from: lg) {
    scroll-margin-top: 100px;
  }
}
