@import "react-datepicker/dist/react-datepicker.css";

.react-datepicker__input-container {
  input {
    @apply block appearance-none w-full border text-gray-700 py-3 px-4 pr-8 rounded leading-tight;
    @apply focus:outline-none focus:bg-white focus:border-gray-500;
  }

  .is-valid & {
    input {
      @apply border-gray-200;
    }
  }

  .is-invalid & {
    input {
      @apply border-red-400;
    }
  }
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
  @include mq($from: md) {
    left: -45px;
  }
}
